var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "wrap x-f",
      attrs: { id: "wrap" },
    },
    [
      _c("categoryTreeCard", {
        attrs: {
          categoryTreeTitle: "仓库设置",
          boxHeight: _vm.boxHeight,
          boxInternalHeight: _vm.boxInternalHeight,
          categoryTreeOptions: _vm.depOptions,
        },
        on: { treeNodeId: _vm.getTreeNodeId },
      }),
      _c(
        "div",
        { staticClass: "rightBox y-b" },
        [
          _c(
            "cardTitleCom",
            {
              staticClass: "headGoodsSearch",
              attrs: { cardTitle: "查询", id: "search-card" },
            },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "navSearch" },
                  [
                    _c("seniorSearch", {
                      attrs: {
                        getList: _vm.getList,
                        dropDownList: _vm.dropDownList,
                        isAdvancedSearch: false,
                        isBillDate: false,
                        isSearchInput: true,
                        isSearchInputTitle: "输入仓库编码、名称",
                      },
                      model: {
                        value: _vm.queryParams,
                        callback: function ($$v) {
                          _vm.queryParams = $$v
                        },
                        expression: "queryParams",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            { staticClass: "headGoodsTable", attrs: { cardTitle: "单据信息" } },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "goodsMessage" },
                  [
                    _c("operatingButton", {
                      attrs: {
                        getList: _vm.getList,
                        isRecycleBtn: true,
                        isDeleteBtn: false,
                        multiple: _vm.multiple,
                      },
                      on: {
                        handleAdd: _vm.handleAdd,
                        handleDelete: _vm.handleDelete,
                        handleRedoDelete: function ($event) {
                          _vm.dialogOptions.show = true
                        },
                      },
                    }),
                    _c(
                      "div",
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loadingTable,
                                expression: "loadingTable",
                              },
                            ],
                            ref: "multipleTable",
                            staticStyle: { width: "100%" },
                            attrs: {
                              data: _vm.tableData,
                              "tooltip-effect": "dark",
                              border: "",
                              "max-height": _vm.tableHeight,
                              height: _vm.tableHeight,
                            },
                            on: {
                              "selection-change": _vm.handleSelectionChange,
                              "row-click": _vm.handleRowClick,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                type: "selection",
                                width: "50",
                                align: "center",
                              },
                            }),
                            _c("el-table-column", {
                              key: "sortNo1",
                              attrs: {
                                label: "序号",
                                width: "80",
                                type: "index",
                                align: "center",
                                prop: "sortNo1",
                              },
                            }),
                            _c("el-table-column", {
                              key: "storeNo",
                              attrs: {
                                prop: "storeNo",
                                label: "仓库编码",
                                "show-overflow-tooltip": true,
                                align: "center",
                                width: "180",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-link",
                                        {
                                          attrs: {
                                            type: "primary",
                                            target: "_blank",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleDetail(scope.row)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(scope.row.storeNo) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              key: "storeName",
                              attrs: {
                                prop: "storeName",
                                label: "仓库名称",
                                "show-overflow-tooltip": true,
                                align: "center",
                                width: "180",
                              },
                            }),
                            _c("el-table-column", {
                              key: "deptName",
                              attrs: {
                                label: "所属机构",
                                align: "center",
                                prop: "deptName",
                                "show-overflow-tooltip": true,
                                width: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "仓库类型",
                                align: "center",
                                prop: "storeType",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("dict-tag", {
                                        attrs: {
                                          options: _vm.dict.type.store_type,
                                          value: scope.row.storeType,
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "仓库状态",
                                align: "center",
                                prop: "status",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("dict-tag", {
                                        attrs: {
                                          options: _vm.dict.type.store_status,
                                          value: scope.row.status,
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              key: "employeeName",
                              attrs: {
                                label: "联系人",
                                align: "center",
                                prop: "employeeName",
                                width: "120",
                                "show-overflow-tooltip": true,
                              },
                            }),
                            _c("el-table-column", {
                              key: "storeTel",
                              attrs: {
                                label: "联系电话",
                                align: "center",
                                prop: "storeTel",
                                width: "110",
                                "show-overflow-tooltip": true,
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "地址",
                                align: "center",
                                prop: "storeAddress",
                                "show-overflow-tooltip": true,
                                "min-width": "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "备注",
                                align: "center",
                                prop: "remark",
                                "min-width": "120",
                                "show-overflow-tooltip": true,
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "修改人",
                                align: "center",
                                prop: "updateBy",
                                width: "120",
                                "show-overflow-tooltip": true,
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "修改时间",
                                align: "center",
                                prop: "updateTime",
                                width: "155",
                                "show-overflow-tooltip": true,
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "创建人",
                                align: "center",
                                prop: "createBy",
                                width: "120",
                                "show-overflow-tooltip": true,
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "创建时间",
                                align: "center",
                                prop: "createTime",
                                width: "155",
                                "show-overflow-tooltip": true,
                              },
                            }),
                          ],
                          1
                        ),
                        _c("bottomPage", {
                          attrs: {
                            getList: _vm.getList,
                            idsCom: _vm.ids,
                            totalCom: _vm.total,
                          },
                          on: { selectAllCom: _vm.selectAll },
                          model: {
                            value: _vm.queryParams,
                            callback: function ($$v) {
                              _vm.queryParams = $$v
                            },
                            expression: "queryParams",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c("c-dialog", {
        attrs: { title: _vm.title, width: 746, showDialog: _vm.open },
        on: {
          "update:showDialog": function ($event) {
            _vm.open = $event
          },
          "update:show-dialog": function ($event) {
            _vm.open = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "el-scrollbar",
                  { staticStyle: { height: "450px" } },
                  [
                    _c(
                      "el-form",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.DetailLoading,
                            expression: "DetailLoading",
                          },
                        ],
                        ref: "form",
                        attrs: {
                          model: _vm.storeForm,
                          rules: _vm.rules,
                          "label-width": "100px",
                        },
                      },
                      [
                        _c("div", { staticClass: "inforTitle" }, [
                          _vm._v("基本信息"),
                        ]),
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "仓库编码",
                                      prop: "storeNo",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: "请输入仓库编码",
                                        maxlength: "11",
                                      },
                                      model: {
                                        value: _vm.storeForm.storeNo,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.storeForm,
                                            "storeNo",
                                            $$v
                                          )
                                        },
                                        expression: "storeForm.storeNo",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "仓库名称",
                                      prop: "storeName",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: "请输入仓库名称",
                                        maxlength: "11",
                                      },
                                      model: {
                                        value: _vm.storeForm.storeName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.storeForm,
                                            "storeName",
                                            $$v
                                          )
                                        },
                                        expression: "storeForm.storeName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "所属机构",
                                      prop: "deptId",
                                    },
                                  },
                                  [
                                    _c("treeselect", {
                                      attrs: {
                                        options: _vm.depOptions,
                                        "show-count": true,
                                        disabled: _vm.forbidden,
                                        placeholder: "请选择所属机构",
                                        normalizer: _vm.normalizer,
                                      },
                                      model: {
                                        value: _vm.storeForm.deptId,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.storeForm, "deptId", $$v)
                                        },
                                        expression: "storeForm.deptId",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "仓库类型",
                                      prop: "storeType",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticClass: "select",
                                        attrs: {
                                          placeholder: "请选择仓库类型",
                                          clearable: "",
                                          disabled: _vm.forbidden,
                                        },
                                        model: {
                                          value: _vm.storeForm.storeType,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.storeForm,
                                              "storeType",
                                              $$v
                                            )
                                          },
                                          expression: "storeForm.storeType",
                                        },
                                      },
                                      _vm._l(
                                        _vm.dict.type.store_type,
                                        function (dict) {
                                          return _c("el-option", {
                                            key: dict.value,
                                            attrs: {
                                              label: dict.label,
                                              value: parseInt(dict.value),
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "status" },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "仓库状态" } },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    model: {
                                      value: _vm.storeForm.status,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.storeForm, "status", $$v)
                                      },
                                      expression: "storeForm.status",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-radio",
                                      {
                                        attrs: {
                                          disabled: _vm.storeForm.status != 0,
                                          label: 0,
                                        },
                                      },
                                      [_vm._v("锁定")]
                                    ),
                                    _c(
                                      "el-radio",
                                      {
                                        attrs: {
                                          disabled: _vm.storeForm.status == 0,
                                          label: 1,
                                        },
                                      },
                                      [_vm._v("正常")]
                                    ),
                                    _c(
                                      "el-radio",
                                      {
                                        attrs: {
                                          disabled: _vm.storeForm.status == 0,
                                          label: 2,
                                        },
                                      },
                                      [_vm._v("停用")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "允许采购",
                                  prop: "isAllowPurchase",
                                },
                              },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    model: {
                                      value: _vm.storeForm.isAllowPurchase,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.storeForm,
                                          "isAllowPurchase",
                                          $$v
                                        )
                                      },
                                      expression: "storeForm.isAllowPurchase",
                                    },
                                  },
                                  [
                                    _c("el-radio", { attrs: { label: true } }, [
                                      _vm._v("是"),
                                    ]),
                                    _c(
                                      "el-radio",
                                      { attrs: { label: false } },
                                      [_vm._v("否")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "允许销售",
                                  prop: "isAllowSale",
                                },
                              },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    model: {
                                      value: _vm.storeForm.isAllowSale,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.storeForm,
                                          "isAllowSale",
                                          $$v
                                        )
                                      },
                                      expression: "storeForm.isAllowSale",
                                    },
                                  },
                                  [
                                    _c("el-radio", { attrs: { label: true } }, [
                                      _vm._v("是"),
                                    ]),
                                    _c(
                                      "el-radio",
                                      { attrs: { label: false } },
                                      [_vm._v("否")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "允许负库存",
                                  prop: "isAllowMinus",
                                },
                              },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    model: {
                                      value: _vm.storeForm.isAllowMinus,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.storeForm,
                                          "isAllowMinus",
                                          $$v
                                        )
                                      },
                                      expression: "storeForm.isAllowMinus",
                                    },
                                  },
                                  [
                                    _c("el-radio", { attrs: { label: true } }, [
                                      _vm._v("是"),
                                    ]),
                                    _c(
                                      "el-radio",
                                      { attrs: { label: false } },
                                      [_vm._v("否")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "计价方式", prop: "costMode" },
                              },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    model: {
                                      value: _vm.storeForm.costMode,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.storeForm, "costMode", $$v)
                                      },
                                      expression: "storeForm.costMode",
                                    },
                                  },
                                  [
                                    _c("el-radio", { attrs: { label: 0 } }, [
                                      _vm._v("移动加权"),
                                    ]),
                                    _c(
                                      "el-radio",
                                      { attrs: { disabled: "", label: 1 } },
                                      [_vm._v("先进先出")]
                                    ),
                                    _c(
                                      "el-radio",
                                      { attrs: { disabled: "", label: 2 } },
                                      [_vm._v("后进先出")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "inforTitle" }, [
                          _vm._v("联系信息"),
                        ]),
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "仓库负责人",
                                      prop: "storeEmployeeId",
                                    },
                                  },
                                  [
                                    _c("treeselect", {
                                      staticStyle: { width: "250px" },
                                      attrs: {
                                        options: _vm.contactsList,
                                        "show-count": true,
                                        placeholder: "请选择仓库负责人",
                                        normalizer: _vm.normalizerContactsList,
                                      },
                                      on: { input: _vm.inputSelect },
                                      model: {
                                        value: _vm.storeForm.storeEmployeeId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.storeForm,
                                            "storeEmployeeId",
                                            $$v
                                          )
                                        },
                                        expression: "storeForm.storeEmployeeId",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "联系电话",
                                      prop: "storeTel",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { maxlength: "11" },
                                      model: {
                                        value: _vm.storeForm.storeTel,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.storeForm,
                                            "storeTel",
                                            $$v
                                          )
                                        },
                                        expression: "storeForm.storeTel",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: { label: "地址", prop: "regionId" },
                                  },
                                  [
                                    _c("el-cascader", {
                                      ref: "tree",
                                      staticStyle: { width: "250px" },
                                      attrs: {
                                        options: _vm.provinceList,
                                        props: _vm.defaultProp,
                                        maxlength: "80",
                                        placeholder: "请选择地址",
                                        filterable: "",
                                        remote: "",
                                        "reserve-keyword": "",
                                        clearable: "",
                                      },
                                      model: {
                                        value: _vm.storeForm.regionId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.storeForm,
                                            "regionId",
                                            $$v
                                          )
                                        },
                                        expression: "storeForm.regionId",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 24 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "详细地址",
                                      prop: "storeAddress",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { maxlength: "200" },
                                      model: {
                                        value: _vm.storeForm.storeRegionAddress,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.storeForm,
                                            "storeRegionAddress",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "storeForm.storeRegionAddress",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 24 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "备注", prop: "remark" } },
                                  [
                                    _c("el-input", {
                                      attrs: { maxlength: "200" },
                                      model: {
                                        value: _vm.storeForm.remark,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.storeForm, "remark", $$v)
                                        },
                                        expression: "storeForm.remark",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "buttonList",
            fn: function () {
              return [
                _c("el-button", { on: { click: _vm.cancel } }, [
                  _vm._v("取 消"),
                ]),
                _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.submitForm } },
                  [_vm._v("确 定")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("Dialog2", {
        attrs: { options: _vm.dialogOptions },
        on: {
          "update:options": function ($event) {
            _vm.dialogOptions = $event
          },
          handleEvent: _vm.handleEvent,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }